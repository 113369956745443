import React from 'react';
import styled from 'styled-components';
import hat from '../assets/images/Christmas-hat.png';
// import ornament from '../assets/images/Christmas-ornament.png';

const HatContainer = styled.div`
  position: fixed;
  top: -30px;
  right: 50px;
  z-index: 3000;
  pointer-events: none;
`;

const OrnamentContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 3000;
  pointer-events: none;
`;

const HatImage = styled.img`
  width: 100px;
  height: auto;
  transform: rotate(15deg);
`;

const OrnamentImage = styled.img`
  width: 80px;
  height: auto;
`;

const ChristmasHat = React.memo(() => {
  const shouldShowDecorations = React.useMemo(() => {
    const today = new Date();
    return today.getMonth() === 10 && today.getDate() === 22;
  }, []);

  if (!shouldShowDecorations) {
    return null;
  }

  return (
    <>
      <HatContainer>
        <HatImage src={hat} alt="Christmas Hat" />
      </HatContainer>
      {/* <OrnamentContainer>
        <OrnamentImage src={ornament} alt="Christmas Ornament" />
      </OrnamentContainer> */}
    </>
  );
});

ChristmasHat.displayName = 'ChristmasHat';

export default ChristmasHat;