import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import '../styles/CreateArticle.css';

const CreateArticle = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://p2pbackendserver-six.vercel.app/api/articles', { title, description, content });
      toast.success(response.data.message);
      navigate('/blog');
    } catch (error) {
      toast.error('Failed to create article');
      console.error(error);
    }
  };

  return (
    <div className="create-article-container">
      <h2>Create Article</h2>
      <form onSubmit={handleSubmit} className="create-article-form">
        <div className="form-group">
          <input placeholder='Title' type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className="form-group">
          <input placeholder='Description' type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
        </div>
        <div className="form-group">
          <textarea placeholder='Content' value={content} onChange={(e) => setContent(e.target.value)} />
        </div>
        <button className='article-button' type="submit">Submit</button>
      </form>
      <Toaster position="top-right" />
    </div>
  );
};

export default CreateArticle;
