import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/DropDateSettings.css';

const DropDateSettings = () => {
  const [schedules, setSchedules] = useState([]);
  const [form, setForm] = useState({
    pickUpLocation: '',
    collectionPoint: '',
    cutOfDate: '',
    dropdate: '',
    goodsOut: ''
  });
  const [currentId, setCurrentId] = useState(null);

  useEffect(() => {
    fetchSchedules();
  }, []);

  const fetchSchedules = async () => {
    try {
      const response = await axios.get('https://p2pbackendserver-six.vercel.app/api/schedules');
      setSchedules(response.data);
    } catch (error) {
      console.error('Error fetching schedules:', error);
    }
  };

  const handleEdit = (schedule) => {
    setForm({
      pickUpLocation: schedule.pickUpLocation,
      collectionPoint: schedule.collectionPoint,
      cutOfDate: schedule.cutOfDate,
      dropdate: schedule.dropdate,
      goodsOut: schedule.goodsOut
    });
    setCurrentId(schedule._id);
  };

  const handleSave = async () => {
    if (!currentId) return; // Exit if no schedule is selected for editing

    try {
      await axios.put(`https://p2pbackendserver-six.vercel.app/api/schedules/${currentId}`, form);
      setSchedules(schedules.map(schedule =>
        schedule._id === currentId ? { ...schedule, ...form } : schedule
      ));
      setForm({
        pickUpLocation: '',
        collectionPoint: '',
        cutOfDate: '',
        dropdate: '',
        goodsOut: ''
      });
      setCurrentId(null); // Clear the form and reset currentId after saving
    } catch (error) {
      console.error('Error updating schedule:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://p2pbackendserver-six.vercel.app/api/schedules/${id}`);
      setSchedules(schedules.filter(schedule => schedule._id !== id));
    } catch (error) {
      console.error('Error deleting schedule:', error);
    }
  };

  return (
    <div className="drop-date-settings">
      <h2>Drop Dates</h2>
      {schedules.length === 0 ? (
        <div className="no-drop-dates">No drop dates created</div>
      ) : (
        <ul className="schedule-list">
          {schedules.map((schedule) => (
            <li key={schedule._id} className="schedule-item">
              <div className="schedule-info">
                <span><strong>{schedule.pickUpLocation}</strong> to <strong>{schedule.collectionPoint}</strong></span>
                <p>Cut-Off Date: {new Date(schedule.cutOfDate).toLocaleDateString()}</p>
                <p>Drop Date: {new Date(schedule.dropdate).toLocaleDateString()}</p>
                <p>Goods Out: {schedule.goodsOut}</p>
              </div>
              <div className="schedule-actions">
                <button onClick={() => handleEdit(schedule)} className="edit-btn">Edit</button>
                <button onClick={() => handleDelete(schedule._id)} className="delete-btn">Delete</button>
              </div>
            </li>
          ))}
        </ul>
      )}

      {/* Edit form */}
      {currentId && (
        <div className="edit-form">
          <h3>Edit Schedule</h3>
          <label>
            Pickup Location:
            <input
              type="text"
              value={form.pickUpLocation}
              onChange={(e) => setForm({ ...form, pickUpLocation: e.target.value })}
            />
          </label>
          <label>
            Collection Point:
            <input
              type="text"
              value={form.collectionPoint}
              onChange={(e) => setForm({ ...form, collectionPoint: e.target.value })}
            />
          </label>
          <label>
            Cut-Off Date:
            <input
              type="date"
              value={form.cutOfDate}
              onChange={(e) => setForm({ ...form, cutOfDate: e.target.value })}
            />
          </label>
          <label>
            Drop Date:
            <input
              type="date"
              value={form.dropdate}
              onChange={(e) => setForm({ ...form, dropdate: e.target.value })}
            />
          </label>
          <label>
            Goods Out Date:
            <input
              type="date"
              value={form.goodsOut}
              onChange={(e) => setForm({ ...form, goodsOut: e.target.value })}
            />
          </label>
          <button onClick={handleSave} className="save-btn">Save</button>
          <button onClick={() => setCurrentId(null)} className="cancel-btn">Cancel</button>
        </div>
      )}
    </div>
  );
};

export default DropDateSettings;
