import React, { useEffect, useState } from 'react';
import '../styles/Legal.css';
import homeImage from '../assets/images/homesections/legal-img.jpeg';
import Logo from '../components/Logo';
import Navbar from '../components/Navbar';

const Legal = () => {
    const [activeTab, setActiveTab] = useState('');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    useEffect(() => {
        // Check if the page has already been reloaded
        if (!sessionStorage.getItem('reloaded')) {
            sessionStorage.setItem('reloaded', 'true');
            window.location.reload();
        }
    }, []);

    const renderContent = () => {
        switch(activeTab) {
            case 'Terms of service':
                return (
                    <>
                    
                        <h2>Terms of Service</h2>
                        <p>Welcome to P2P Logistics! These terms and conditions govern your use of our website and services. Please read these terms carefully before using our website or services. By accessing or using our website and services, you agree to be bound by these terms. If you do not agree with any part of these terms, you must not use our website or services.</p>

                        <h3>Agreement to Terms</h3>
                        <p>By accessing or using our website and services, you agree to abide by these terms and conditions. These terms apply to all users of our website, including without limitation users who are browsers, customers, merchants, and/or contributors of content.</p>

                        <h3>Scope</h3>
                        <p>P2P Logistics offers logistics and shipping services exclusively from the UK to Ghana. Our services encompass a range of offerings, including but not limited to package handling, customs clearance, and international shipping. Additional terms and conditions may apply to specific services, and users are subject to those terms when utilizing such services.</p>

                        <h3>Changes to Terms</h3>
                        <p>We reserve the right to modify these terms at any time without prior notice. Any changes to these terms will be effective immediately upon posting on our website. It is your responsibility to review these terms periodically for updates. Your continued use of our website and services after any modifications indicates your acceptance of the modified terms.</p>

                        <h3>Changes to the Website</h3>
                        <p>We may update, modify, or discontinue any aspect of our website or services at any time without prior notice. We are not liable for any consequences resulting from the unavailability or modification of our website or services. We may also restrict access to certain parts of our website or the entire website without notice or liability.</p>

                        <h3>Privacy</h3>
                        <p>Protecting your privacy is important to us. We collect, use, and disclose personal information in accordance with our Privacy Policy, which forms part of these terms. By using our website and services, you consent to the collection, use, and disclosure of your personal information as described in our Privacy Policy.</p>

                        <h3>Your Account</h3>
                        <p>To access certain features of our website and services, you may need to create an account and provide certain information. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to provide accurate, current, and complete information when creating an account and to update your information as necessary to keep it accurate, current, and complete.</p>

                        <h3>Account Verification</h3>
                        <p>For security purposes and to comply with legal requirements, we may require customers to provide proof of identity and address verification. Failure to provide requested verification may result in the suspension or termination of your account and access to our services.</p>

                        <h3>Services</h3>
                        <p>P2P Logistics provides logistics and shipping services from the UK to Ghana. Our services include package handling, consolidation, customs clearance, and international shipping. We strive to ensure the safe and timely delivery of packages to our customers in Ghana.</p>

                        <h3>Paid Services and Fees</h3>
                        <p>Some of our services require payment, such as shipping fees and service charges. The applicable fees are outlined on our website and may vary based on the weight and dimensions of the package.</p>

                        <h3>Customer Responsibilities</h3>
                        <p>As a customer, you are responsible for providing accurate information when using our platform. You must ensure that all shipped goods comply with the laws and regulations of both the UK and Ghana.</p>

                        <h3>Right to Decline</h3>
                        <p>We reserve the right to decline or cancel any service request for reasons including but not limited to suspected fraud, violation of our terms, or inability to fulfill the request.</p>

                        <h3>Compliance with Law</h3>
                        <p>Customers must comply with all applicable laws and regulations governing the shipment of goods from the UK to Ghana. This includes restrictions on prohibited items and adherence to customs regulations.</p>

                        <h3>Shipments</h3>
                        <p>We handle the shipment of goods from the UK to Ghana on behalf of our customers. Shipping procedures and guidelines are provided on our website, and customers are responsible for adhering to them.</p>

                        <h3>Damage or Loss</h3>
                        <p>While we take precautions to ensure the safe delivery of your goods, we offer shipping insurance to cover potential damage or loss during transit. Customers are encouraged to opt for insurance coverage for added protection.</p>

                        <h3>Return Policy</h3>
                        <p>We assist customers in returning items to UK online stores, subject to the return policies of the respective merchants. Customers are responsible for contacting the store and arranging for return shipping labels.</p>

                        <h3>No Class Actions</h3>
                        <p>Disputes must be resolved on an individual basis, and class actions are not permitted.</p>

                        <h3>Termination of Use</h3>
                        <p>We reserve the right to suspend or terminate your access to our platform if you violate these terms or engage in fraudulent activities.</p>

                        <h3>Governing Law</h3>
                        <p>These terms are governed by the laws of the UK. Any disputes arising out of or related to these terms shall be resolved in the courts of the UK.</p>

                        <h3>Disclaimer</h3>
                        <p>We provide our services "as is" and disclaim any warranties, express or implied. We are not liable for any damages resulting from the use of our platform.</p>

                    </>
                );
            case 'Privacy policy':
                return (
                    <>
                     <Logo />
                        <h2>Privacy Policy</h2>
                        <p>By visiting our website and using our services, you agree to the practices outlined in this Privacy Policy. We are committed to protecting your privacy and ensuring the security of your personal information. Please read this policy carefully to understand how we collect, store, and use your information.</p>


                        <h3>Personal Information Collection</h3>

                        <p>We collect personal information from you to support and enhance our relationship with you. This includes your name, postal address, email address, telephone number, and any other information defined as personal or personally identifiable under applicable law.</p>

                        <h3>Technical Information</h3>

                        <p>In addition to personal information, we collect technical information about your internet connection and the devices you use to access our website, such as IP addresses, device types, and browsing actions.</p>

                        <h3>How We Use Your Information</h3>

                        <p>We use the information collected for various purposes, including providing personalized services, communicating with you regarding orders and inquiries, improving our website functionality, and marketing purposes.</p>

                        <h3>Disclosure of Your Information</h3>

                        <p>We do not sell your personal information to third parties. However, we may disclose your information to trusted partners and suppliers to enable us to provide services to you. We may also disclose your information as required by law or in the event of a merger or acquisition.</p>

                       

                        <h3>Information Security</h3>

                        <p>We take measures to safeguard the confidentiality of your information, including physical, electronic, and procedural safeguards. However, no security system is perfect, and we cannot guarantee protection against all potential security breaches.</p>

                       

                        <h3>Updates to the Privacy Policy</h3>

                        <p>We reserve the right to update this Privacy Policy periodically. Your continued use of our website after changes to the Privacy Policy indicates your acceptance of those changes.</p>

                        

                        <h3>Automatic Data Collection</h3>

                        <p>As you navigate through our website, we may use automatic data collection technologies to collect information about your equipment, browsing actions, and patterns. This helps us improve our website and deliver a better service.</p>


                        <h3>Use of Cookies and Tracking Technologies</h3>

                        <p>We use cookies and other tracking technologies to improve your browsing experience and personalize our services. These technologies help us estimate audience size, store information about your preferences, and recognize you when you return to our website.</p>


                        <h3>Third-party Services</h3>

                        <p>Some content or applications on our website are served by third parties, including advertisers and application providers. These third parties may use cookies and tracking technologies to collect information about your online activities. Please review the privacy policies of these third-party sites for more information.</p>


                        <h3>Interpretation Section</h3>

                        <ul>
                            <li><strong>Privacy:</strong> Privacy refers to the protection of your personal information and the right to control how it is collected, used, and shared by organizations.</li>
                            <li><strong>Personal Information:</strong> Personal information includes any data that identifies or can be used to identify an individual, such as name, address, email address, and phone number.</li>
                            <li><strong>Technical Information:</strong> Technical information includes details about your internet connection, device type, browser type, and usage details.</li>
                            <li><strong>Information Usage:</strong> Information usage refers to the purposes for which we collect and use your personal information, including providing services, communication, and marketing.</li>
                            <li><strong>Information Disclosure:</strong> Information disclosure outlines the circumstances under which we may disclose your personal information to third parties.</li>
                            <li><strong>Information Security:</strong> Information security measures are implemented to protect the confidentiality of your information, including physical, electronic, and procedural safeguards.</li>
                            <li><strong>Changes to Privacy Policy:</strong> Changes to the Privacy Policy describe our process for updating the policy and your responsibility to review and accept changes.</li>
                            <li><strong>Cookies and Tracking Technologies:</strong> Cookies and tracking technologies are used to enhance your browsing experience and personalize our services.</li>
                            <li><strong>Third-party Services:</strong> Third-party services refer to content or applications served by third parties on our website, which may collect information about your online activities.</li>
                        </ul>

                        <h3>Eligibility Requirements</h3>

                        <p>To access and utilize P2P Logistics services, individuals must be at least 18 years old and capable of entering into legally binding contracts. By using our application, you affirm that you meet the age requirement and have the legal capacity to enter into contracts. Individuals under the age of 18 are not permitted to use our Services. P2P Logistics reserves the right to refuse service to any individual or entity and may modify eligibility criteria at its discretion. Users are responsible for ensuring compliance with all applicable laws, rules, and regulations, and access to our Services may be revoked if such compliance is not met.</p>

                        <h3>Agreement to Terms and Conditions</h3>

                        <p>By accessing our website and/or mobile applications, users agree to abide by the terms and conditions outlined herein. The use of P2P Logistics services is contingent upon acceptance of all terms and conditions, including any additional operating rules, policies, and procedures published by the Company. Certain Services may be subject to additional terms and conditions specified by P2P Logistics from time to time, which are incorporated into these Terms. These Terms apply to all users of our Services, including contributors of content, information, and materials, whether registered or not.</p>

                        <h3>Account Creation</h3>

                        <p>All users must create a P2P Logistics account to begin using our services. If creating an account on behalf of a company or legal entity, users must have the authority to legally bind that entity and grant permissions and licenses to P2P Logistics as outlined in these terms. Account creation requires a valid email address and password. By creating an account, users agree to provide accurate and current information and to maintain the security of their account credentials. Users are responsible for all activities conducted under their account and must promptly report any unauthorized use or security breaches.</p>

                        <h3>Criteria for Unacceptable Shipments</h3>

                        <p>Shipments are deemed unacceptable if they meet any of the following criteria:
                        <ul>
                        <li>Failure to make a customs declaration when required.</li>
                        <li>Contain prohibited items such as counterfeit goods, perishable items, weapons, hazardous materials, or illegal substances.</li>
                        <li>Classified as hazardous material, dangerous goods, or prohibited articles by relevant organizations.</li>
                        <li>Incorrectly addressed, inadequately marked, or improperly packaged for safe transportation.</li>
                        </ul>
                        </p>

                        <h3>Delivery Process and Undeliverable Shipments</h3>

                        <p>P2P Logistics does not deliver to PO Boxes or Army bases. Shipments are delivered to the receiver's address provided by the shipper, not necessarily to the named receiver personally. Topship reserves the right to notify the receiver of delivery updates and may offer alternative delivery options. If a shipment is deemed unacceptable, efforts will be made to return it to the shipper at the shipper's cost.</p>

                     
                        <h3>Shipment Inspection</h3>

                        <p>P2P Logistics and its partners have the right to inspect shipments without notice for safety, security, customs, or other regulatory reasons.</p>

                        <h3>Charges and Billing Process</h3>

                        <p>P2P Logistics calculates shipment charges based on the final destination, weight, and volumetric weight per piece. Charges may be re-evaluated to confirm accuracy.</p>
                                            </>
                );
            case 'Prohibited Items':
                return (
                    <>
            <div className="shipping-guidelines-container">
            <h2>Prohibited and Limited Shipping Guidelines</h2>
            <div className="box-grid">
                <div className="box">
                    <h3>Prohibited Items</h3>
                    <ul>
                        <li>Aerosols</li>
                        <li>Agriculture products</li>
                        <li>Animals</li>
                        <li>Biohazards</li>
                        <li>CBD products</li>
                        <li>Corrosive substances</li>
                        <li>Counterfeit goods</li>
                        <li>Drug contraband</li>
                        <li>Explosives</li>
                        <li>Firearms</li>
                        <li>Hazardous materials & Dangerous Goods</li>
                        <li>Hoverboards</li>
                        <li>Ivory</li>
                        <li>Means of payment</li>
                        <li>Poison</li>
                        <li>Political materials</li>
                        <li>Pornography</li>
                        <li>Radioactive materials</li>
                        <li>Soil</li>
                        <li>Weapons</li>
                        <li>Used and Refurbished Electronics (Rwanda only)</li>
                    </ul>
                </div>
                <div className="box">
                    <h3>Limited Items</h3>
                    <ul>
                        <li>Alcohol</li>
                        <li>Art & Antiquities</li>
                        <li>Lead Acid Batteries</li>
                        <li>Phones With Pre-Assigned Numbers</li>
                        <li>e-cigarettes</li>
                        <li>E-Juice</li>
                        <li>Electric bikes over 100Wh</li>
                        <li>Fertilizers and Pesticides</li>
                        <li>Flammable/Combustible items</li>
                        <li>Tactical Knives</li>
                        <li>Gambling-related items</li>
                        <li>Liquids</li>
                        <li>Lithium Batteries</li>
                        <li>Loose Precious Stones</li>
                        <li>Loose Lithium Batteries</li>
                        <li>Tobacco and Nicotine Products</li>
                        <li>Perfume/Cologne</li>
                        <li>Perishable Food</li>
                        <li>Plants</li>
                        <li>Stamps</li>
                        <li>Tactical Equipment</li>
                        <li>Sim Cards</li>
                    </ul>
                </div>
            </div>
        </div>
                    </>
                );
            case 'Fraud Awareness':
                return (
                    <>
                    <h3>Fraud Awareness</h3>
                    <p>Protect yourself from online fraud by being vigilant. Here are some tips:</p>
                    <ul>
                        <li>Only purchase from reputable online stores.</li>
                        <li>Check for secure payment methods and websites (look for HTTPS in the URL).</li>
                        <li>Avoid deals that seem too good to be true.</li>
                        <li>Be cautious of unsolicited emails or messages asking for personal information.</li>
                        <li>Report any suspicious activity to the appropriate authorities.</li>
                    </ul>
                
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className="legal-container">

            <div className="home">
                <div className="overlay"><h1>Legal</h1></div>
                <img className='homeimage' src={homeImage} loading='lazy' alt="legal-Home-img" />
            </div>

            <div className='legal-section-details'>
                <div className="tabs-section">
                    <div
                        className={`tab ${activeTab === 'Terms of service' ? 'active' : ''}`}
                        onClick={() => handleTabClick('Terms of service')}
                    >
                        Terms of Service
                    </div>
                    <div
                        className={`tab ${activeTab === 'Privacy policy' ? 'active' : ''}`}
                        onClick={() => handleTabClick('Privacy policy')}
                    >
                        Privacy Policy
                    </div>
                    <div
                        className={`tab ${activeTab === 'Prohibited Items' ? 'active' : ''}`}
                        onClick={() => handleTabClick('Prohibited Items')}
                    >
                        Prohibited Items
                    </div>
                    <div
                        className={`tab ${activeTab === 'Fraud Awareness' ? 'active' : ''}`}
                        onClick={() => handleTabClick('Fraud Awareness')}
                    >
                        Fraud Awareness
                    </div>
                </div>

                <div className="info-section">
                    <div className="info-content">
                    {renderContent()}
                    </div>
                </div>
            </div>
            <Navbar />
        </div>
    );
};

export default Legal;
