import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Schedules.css';
import shipping from '../assets/images/shipping-vid.mp4';
import Logo from '../components/Logo';
import Navbar from '../components/Navbar';

const Schedule = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchSchedules();
  }, []);

  const fetchSchedules = async () => {
    try {
      const response = await axios.get('https://p2pbackendserver-six.vercel.app/api/schedules');
      setData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching schedules:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const filtered = data.filter(item =>
      Object.values(item).some(val =>
        String(val).toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredData(filtered);
  }, [data, searchQuery]);

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePrint = () => {
    const printableContent = document.getElementById('schedule-printable-content');
    const originalDocument = document.body.innerHTML;
    document.body.innerHTML = printableContent.innerHTML;
    window.print();
    document.body.innerHTML = originalDocument;
  };

  const formatReadableDate = (isoDateString) => {
    const date = new Date(isoDateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <div className="main-schedule-container">
      <Logo />
      <div className="schedule-home-section">
        <video loading='lazy' src={shipping} autoPlay loop muted preload="none" />
        <div className="schedule-overlay"></div>
        <div className="schedule-content">
          <h1>Drop Dates</h1>
        </div>
      </div>

      <div className="schedule-container">
        <div className="search-input">
          <input
            type="text"
            placeholder="Filter..."
            value={searchQuery}
            onChange={handleInputChange}
          />
          <img
            loading='lazy'
            onClick={handlePrint}
            width="30"
            height="30"
            src="https://img.icons8.com/dotty/80/1b2634/print.png"
            alt="print-img"
          />
        </div>
        <div id="schedule-printable-content">
          {isLoading ? (
            <p style={{ color: 'black' }}>Loading Drop Dates...</p>
          ) : (
            <>
              {filteredData.length > 0 ? (
                <table className="schedule-table">
                  <thead>
                    <tr>
                      <th>Pick-up location</th>
                      <th>Collection Point</th>
                      <th>Cut-off Date</th>
                      <th>Drop Date</th>
                      <th>Goods-Out</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.pickUpLocation}</td>
                        <td>{item.collectionPoint}</td>
                        <td>{formatReadableDate(item.cutOfDate)}</td>
                        <td>{formatReadableDate(item.dropdate)}</td>
                        <td>{formatReadableDate(item.goodsOut)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : searchQuery ? (
                <p style={{ color: "black", textAlign: 'center' }}>No results found for "{searchQuery}".</p>
              ) : (
                <p style={{ color: "black", textAlign: 'center' }}>No Drop Dates currently available.</p>
              )}
            </>
          )}
        </div>
      </div>
      <Navbar />
    </div>
  );
};

export default Schedule;
