import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Navbar.css';
import axios from 'axios';
import logo from '../assets/images/logoblacktext.png';
import '../styles/Darklogo.css';
import { toast } from 'react-hot-toast';
import Icon from '../assets/images/logoblacktext.png';
import { startTransition } from 'react';
import Profile from '../components/Profile';
import ChristmasHat from './ChristmasHatTheme';


const Navbar = React.memo(() => {
  const [isScrolling, setIsScrolling] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [showOverlayMenu, setShowOverlayMenu] = useState(false);
  const [showcopysection, setShowcopysection] = useState(false);
  const [showdashbutton, setshowdashbutton] = useState(false);
  const [showuserdashbutton, setshowuserdashbutton] = useState(false);
  const [address, setAddress] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate(); 

  const handleNavigation = () => {
    startTransition(() => {
      navigate('/');
    });
  };
  
  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY;
    if (scrollTop < 50 && !isScrolling) {
      setIsScrolling(true);
      setShowNav(false);
    } else if (scrollTop >= 50 && isScrolling) {
      setIsScrolling(false);
      setShowNav(true);
    } else if (scrollTop < 50 && !isScrolling) {
      setShowNav(true);
    }
  }, [isScrolling]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const toggleOverlayMenu = useCallback(() => {
    setShowOverlayMenu(!showOverlayMenu);
  }, [showOverlayMenu]);

  const checkLoggedIn = () => {
    const token = localStorage.getItem('token');
    return token !== null;
  };
  
  const getUsername = () => {
    return localStorage.getItem('username');
  };

  const getemail = () => {
    return localStorage.getItem('email');
  };


  const handleModalClick = (event) => {
    event.stopPropagation();
  };

  const isLoggedIn = checkLoggedIn();
  const username = getUsername();
  const email = getemail();

  const handleShipWithUsClick = useCallback(() => {
    setShowOverlayMenu(false);
    if (isLoggedIn) {
      navigate('/process');
    } else {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);


  useEffect(() => {
    const isadmin = localStorage.getItem('username') === 'p2p_superadmin';
    if(isadmin){
      setshowdashbutton(true)
    }
    setshowuserdashbutton(true)
  }, [isLoggedIn]);


  const handleLogout = async () => {
    // Logout logic here
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('_id');
    toast.success('Logout successful');
    window.location.reload()
  };


  const dropDowAaddresscopy = async () => {
    const response = await axios.get('https://p2pbackendserver.vercel.app/getaddress');
    setAddress(response.data.address);
    setShowcopysection(!showcopysection);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(address);
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 3000);
  };


  return (
    <>
      <nav className={`desktop-navbar ${showNav ? 'visible' : 'hidden'}`}>
      <ChristmasHat />
        <div className="navbar-container">
        <div onClick={handleNavigation} className='logo-container' style={{ cursor: 'pointer' }}>
          <img loading='lazy' src={logo} alt='P2P-logo-img' />
        </div>
          <div className="navbar-links">
            <a onClick={handleShipWithUsClick}>Ship with Us</a>
            <Link to="/about">About</Link>
            <Link to="/contact">Contact</Link>
            <Link to="/schedule">Drop Dates</Link>
            {!isLoggedIn ? (
                <Link to="/login">Login</Link>
            ) : (
                null
            )}
            <Link to="/register">Sign up</Link>
          </div>
          <Profile />
        </div>
      </nav>

      <div className="mobile-navbar">
        <div
          className={`hamburger-menu ${showOverlayMenu ? 'open' : ''}`}
          onClick={toggleOverlayMenu}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>

        {showOverlayMenu && 
        <div className='nav-overlay' onClick={toggleOverlayMenu}>
          <div className='overlay-menu' onClick={handleModalClick}>
          {/* <div className='menu-footer'>
              <div className='innermenufooter'>
              <div className='footer-items'>
              {isLoggedIn && (
              <div className='footer-item'>
              <p>{username}</p>
              <p>{email}</p>
              </div>
              )}
              </div>
              </div>
           </div> */}

                
             <div className='footer-item'>
                    <img width="100" height="100" src={Icon} alt="icon-nav"/>
                    <div className='footer-item-info'>
                    {isLoggedIn && (
                      <div className='si'>
                        <p ><strong>{username}</strong></p>
                        <p style={{fontSize: "8px"}}>{email}</p>
                    </div>
                     )}
                    </div>
                  </div>
                  {/* <div className="close-menu" onClick={toggleOverlayMenu}></div> */}
                  


                   
  <div className='NavLinks'>
              <div className='link-icon'>
                  <img width="10" height="10" src="https://img.icons8.com/fluency-systems-regular/48/airplane-mode-on.png" alt="airplane"/>
                  <a onClick={handleShipWithUsClick} className='side-nav-lnks'>Ship with Us</a>
               </div>

               <div className='link-icon'>
                  <img width="10" height="10" src="https://img.icons8.com/isometric-line/50/about.png" alt="about"/>
                  <Link onClick={toggleOverlayMenu} className='side-nav-lnks' to="/about">About</Link>
              </div>

              <div className='link-icon'>
                  <img width="10" height="10" src="https://img.icons8.com/windows/32/contact-card.png" alt="contact-card"/>
                  <Link onClick={toggleOverlayMenu} className='side-nav-lnks' to="/contact">Contact</Link>
             </div>

               <div className='link-icon'>
                  <img width="10" height="10" src="https://img.icons8.com/ios/50/overtime--v1.png" alt="overtime"/>
                  <Link onClick={toggleOverlayMenu} className='side-nav-lnks' to="/schedule">Drop Dates</Link>
                </div>

                <div className='link-icon'>
                <img width="10" height="10" src="https://img.icons8.com/ios/50/signing-a-document.png" alt="signing-document"/>
                  <Link onClick={toggleOverlayMenu} className='side-nav-lnks' to="/blog">Blog</Link>
                </div>


                {showdashbutton && isLoggedIn && (
                  <div className='link-icon'>
                  <img width="10" height="10" src="https://img.icons8.com/ios/50/control-panel--v2.png" alt="dashboard-link"/>
                    <Link onClick={toggleOverlayMenu} className='side-nav-lnks' to="/dashboard"><strong>Admin Dashboard</strong></Link>
                  </div>
                )}


                {showuserdashbutton && isLoggedIn && (
                  <div className='link-icon'>
                  <img width="10" height="10" src="https://img.icons8.com/ios/50/control-panel--v2.png" alt="dashboard-link"/>
                    <Link onClick={toggleOverlayMenu} className='side-nav-lnks' to="/profile"><strong>Profile</strong></Link>
                  </div>
                )}


                <div className='link-icon'>
                <img width="10" height="10" src="https://img.icons8.com/ios/50/order-on-the-way.png" alt="order-on-the-way"/>
                  <Link onClick={toggleOverlayMenu} className='side-nav-lnks' to="/tracking-page">Tracking</Link>
                </div><br /><br />
                

  </div>





  <div className='menu-footer'>


                  {!isLoggedIn ? (
                      <div className='link-icon'id='authlinkone'>
                      <Link onClick={toggleOverlayMenu}  className='side-nav-lnks' to="/login">Login</Link>
                      </div>
                  ) : (
                    <p style={{color: "red"}} onClick={handleLogout}>Logout</p>
                  )}


              <div className='link-icon' id='authlinktwo'>
                <Link onClick={toggleOverlayMenu} className='side-nav-lnks' to="/register">Sign up</Link>
              </div>


              <br />
          {isLoggedIn && !showcopysection && (
            <button className='nav-addri' onClick={dropDowAaddresscopy}>Get Address</button>
          )}
          {showcopysection && isLoggedIn && (
            <div className="profile-copy-address-container nav-addri">
              <div className="profile-copy-address">
                <p>{address}</p>
                <svg
                  onClick={copyToClipboard}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  color="#fffff"
                  fill="none"
                >
                  <path
                    d="M16 2H12C9.17157 2 7.75736 2 6.87868 2.94627C6 3.89254 6 5.41554 6 8.46154V9.53846C6 12.5845 6 14.1075 6.87868 15.0537C7.75736 16 9.17157 16 12 16H16C18.8284 16 20.2426 16 21.1213 15.0537C22 14.1075 22 12.5845 22 9.53846V8.46154C22 5.41554 22 3.89254 21.1213 2.94627C20.2426 2 18.8284 2 16 2Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M18 16.6082C17.9879 18.9537 17.8914 20.2239 17.123 21.0525C16.2442 22 14.8298 22 12.0011 22H8.00065C5.17192 22 3.75755 22 2.87878 21.0525C2 20.1049 2 18.5799 2 15.5298V14.4515C2 11.4014 2 9.87638 2.87878 8.92885C3.52015 8.2373 4.44682 8.05047 6.00043 8"
                    stroke="currentColor"
                    strokeWidth="1.5"
                  />
                </svg>
              </div>
              {showMessage && (
                <p className='address-success-modal'>Copied to clipboard</p>
              )}
            </div>
          )}
  
  </div>

          </div>
        </div>
        }
      </div>
    </>
  );
});

export default Navbar;
