import React from 'react'
import '../styles/Contact.css'
// import Logo from '../components/Darklogo';
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom';

function Contact() {

  const handleinsta = () => {
    window.open('https://www.instagram.com/imecovid19/', '_blank');
  }

  const handlephone = () => {
    window.open('tel:+233 50 389 0000');
  }

  const handleemail = () => {
    window.open('p2plogistics' + '@' + 'gmail.com');
  }

    const handleWhatsAppClick = () => {
      const phoneNumber = '+233503890000';
      const message = 'Hello!';
      const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
      window.open(whatsappURL, '_blank');
    };

  return (
  <div className='contact-main'>
      {/* <Logo /> */}
        <div className='contact-home'>
          <h1>Contact Us</h1>
        </div>
    <div className='contact-submain'>

      <div className='contact-query'>
        <h1>Get in touch</h1>
        <div className='contact-bar'></div>
        <p>Do you want to check the <Link className='span' to="/tracking-page">status</Link> of your shipment? or have any questions about our services?, Send us a message or Contact any of the means below. We respond to all queries within 24 business hours.</p>
      </div>

      <div className='contact-container'>

        <div className='contact-text'>
          <h1>Need help?</h1>
          <p>Our customer service team is here to help you with any questions you may have</p>
        </div>

        <div className='boxes'>
            <div onClick={handleinsta} className='first-box'>
            <img loading='lazy' width="50" height="50" src="https://img.icons8.com/clouds/100/instagram-new--v1.png" alt="instagram"/>
            <p>Instagram</p>
            </div>
            <div onClick={handlephone} className='second-box'>
            <img loading='lazy' width="50" height="50" src="https://img.icons8.com/dusk/64/phone-disconnected.png" alt="phone"/>
            <p>Phone</p>
            </div>
            <div onClick={handleWhatsAppClick} className='third-box'>
            <img loading='lazy' width="50" height="50" src="https://img.icons8.com/bubbles/50/whatsapp.png" alt="whatsapp"/>
            <p>Whatsapp</p>
            </div>
            <div onClick={handleemail} className='fourth-box'>
            <img loading='lazy' width="50" height="50" src="https://img.icons8.com/plasticine/100/new-post--v2.png" alt="email"/>
            <p>Email</p>
            </div>
        </div>

       </div>
      </div>
      <Navbar />
    </div>
  )
}

export default Contact
