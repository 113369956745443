import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../styles/ScheduleAdmin.css';
import { toast } from 'react-hot-toast';

const ScheduleAdmin = () => {
  // const [schedules, setSchedules] = useState([]);
  const [form, setForm] = useState({
    pickUpLocation: '',
    collectionPoint: '',
    cutOfDate: '',
    dropdate: '',
    goodsOut: '',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const buttonref = useRef(null);

  // useEffect(() => {
  //   fetchSchedules();
  // }, []);

  // const fetchSchedules = async () => {
  //   try {
  //     const response = await axios.get('https://p2pbackendserver-six.vercel.app/api/schedules');
  //     if (!response || !response.data || response.data.length === 0) {
  //       console.log('No schedules found.');
  //       setSchedules([]);
  //       return;
  //     }
  //     setSchedules(response.data);
  //   } catch (error) {
  //     console.error('Error fetching schedules:', error);
  //   }
  // };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEditing) {
      await axios.put(`https://p2pbackendserver-six.vercel.app/api/schedules/${currentId}`, form);
    } else {
      await axios.post('https://p2pbackendserver-six.vercel.app/api/schedules', form);
    }
    buttonref.current.disabled = true;
      toast.success('successfully createed dropdate');
    setForm({ pickUpLocation: '', collectionPoint: '', cutOfDate: '', dropdate: '', goodsOut: '' });
    setIsEditing(false);
    setCurrentId(null);
    // fetchSchedules();
  };

  // const handleEdit = (schedule) => {
  //   setForm(schedule);
  //   setIsEditing(true);
  //   setCurrentId(schedule._id);
  // };

  // const handleDelete = async (id) => {
  //   await axios.delete(`https://p2pbackendserver-six.vercel.app/api/schedules/${id}`);
  //   fetchSchedules();
  // };

  return (
    <div className="admin-container">
      <h2>Create Drop Dates</h2>
      <p style={{ color: "black" }}>Create, edit, update and delete Drop Dates here</p>
      <form onSubmit={handleSubmit} className="admin-form">
        <div>
          <label htmlFor='pickUpLocation'>Pickup location</label>
          <input
            type="text"
            name="pickUpLocation"
            placeholder="Pick Up Location"
            value={form.pickUpLocation}
            onChange={handleChange}
          />
        </div>

        <div>
          <label htmlFor='collectionPoint'>Collection Point</label>
          <input
            type="text"
            name="collectionPoint"
            placeholder="Collection Point"
            value={form.collectionPoint}
            onChange={handleChange}
          />
        </div>

        <div>
          <label htmlFor='cutOfDate'>Cut of Date</label>
          <input
            type="date"
            name="cutOfDate"
            placeholder="Cut Off Date"
            value={form.cutOfDate}
            onChange={handleChange}
          />
        </div>

        <div>
          <label htmlFor='dropdate'>Drop date</label>
          <input
            type="date"
            name="dropdate"
            placeholder="Drop Date"
            value={form.dropdate}
            onChange={handleChange}
          />
        </div>

        <div>
          <label htmlFor='goodsOut'>Goods out</label>
          <input
            type="date"
            name="goodsOut"
            placeholder="GoodsOut"
            value={form.goodsOut}
            onChange={handleChange}
          />
        </div>
        <button ref={buttonref} type="submit">{isEditing ? 'Update' : 'Create'}</button>
      </form>

      {/* <h2>Drop Dates</h2>
      <ul className="schedule-list">
        {schedules.map((schedule) => (
          <li key={schedule._id} className="schedule-item">
            <div className="schedule-info">
              {schedule.pickUpLocation} to {schedule.collectionPoint} on {schedule.cutOfDate} at {schedule.dropdate} goods out {schedule.goodsOut}
            </div>
            <div className="schedule-actions">
              <button onClick={() => handleEdit(schedule)}>Edit</button>
              <button onClick={() => handleDelete(schedule._id)}>Delete</button>
            </div>
          </li>
        ))}
      </ul> */}
    </div>
  );
};

export default ScheduleAdmin;
