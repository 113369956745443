import React from 'react'
import '../styles/About.css';
// import Logo from '../components/Logo';
import Navbar from '../components/Navbar';
import YoutubeEmbedd from '../components/YoutubeEmbedd';


function About() {
  return (
    <div className='about-section'>
      {/* <Logo /> */}
          <div className="home-section">
                <div className="about-overlay"></div>
                <div className="about-left-column">
                    <h1>Who are we?</h1>
                    <p>P2P Logistics is a logistics company that provides shipping services to individuals and businesses. We are dedicated to providing the best shipping services to our customers. We are a team of dedicated professionals who ensure that your packages are delivered to you in a timely manner.</p>
                </div>
            </div>

            <div className="highlights-section">
                <div className="hightlights-text">
                    <h1>Our Highlights</h1>
                </div>

                <div className="highlightboxes">
                    <div className="highlightbox">
                        <h1>2022</h1>
                        <p>Commenced business</p>
                    </div>

                    <div className="highlightbox">
                        <h1>100+ </h1>
                        <p>Sucessful shipment and counting</p>
                    </div>

                    <div className="highlightbox">
                        <h1>Hub</h1>
                        <p>Accra, Ghana.</p>
                    </div>
                    
                </div>
          
          </div>


          <div className="our-mission-section">

            <h1>Our Mission</h1>
            <p>At P2P Logistics, our mission is to excel as a premier logistics company, delivering unparalleled shipping services to individuals and businesses. With a steadfast commitment to excellence, we strive to ensure the swift and reliable delivery of packages, driven by a team of dedicated professionals. Our mission is rooted in the core belief that every package represents a promise, and we are steadfast in fulfilling that promise by providing exceptional service, reliability, and timeliness to our valued customers.</p>


          </div>


          {/* <div className="our-team-section">

            <div className="team-heading">
              <p className='mini-heading'>Our Team</p>
              <h1>The people behind us</h1>
            </div>

            <div className="team-members">
              <div className="team-member">
                  <img src="" alt=""/>
                  <h1>John Doe</h1>
                  <p>CEO</p>
                </div>

                <div className="team-member">
                  <img src="" alt=""/>
                  <h1>Jane Doe</h1>
                  <p>COO</p>
                </div>

                <div className="team-member">
                  <img src="" alt=""/>
                  <h1>John Doe</h1>
                  <p>CEO</p>
                </div>

                <div className="team-member">
                  <img src="" alt=""/>
                  <h1>Jane Doe</h1>
                  <p>COO</p>
                </div>

            </div>


          </div> */}


          <div className="contact-section">
                <h1>Contact Us</h1>

                <div className='contact'>

                   <div className="contact-info-left">
                        <img width="64" height="64" src="https://img.icons8.com/nolan/64/address.png" loading='lazy' alt="address"/>
                        <div className='contactinfo'>
                            <label>Location: </label>
                            <p>181, Orchid Street, East Airport-Accra</p>
                        </div>
                   </div>                        

                    <div className="contact-info-left">
                        <img width="64" height="64" src="https://img.icons8.com/nolan/64/apple-phone.png"  loading='lazy' alt="phone"/>
                        <div className='contactinfo'>
                            <label>Phone: </label>
                            <p>+233 50 389 0000</p>
                        </div>
                    </div>

                    <div className="contact-info-left">
                        <img width="64" height="64" src="https://img.icons8.com/nolan/64/email.png" loading='lazy' alt="email"/>
                        <div className='contactinfo'>
                            <label>Email: </label>
                            <p>p2plogistics@gmail.com</p>
                        </div>
                   </div>                        

                   <div className="contact-info-left">
                        <img width="64" height="64" src="https://img.icons8.com/nolan/64/instagram-new.png" loading='lazy' alt="instagram"/>
                        <div className='contactinfo'>
                            <label>Instagram: </label>
                            <p>@p2plogisticsgh</p>
                        </div>
                   </div>                       

               </div>
           

          </div>
          <YoutubeEmbedd />
        <Navbar />
    </div>
  )
}

export default About
