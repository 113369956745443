import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/CreateTracking.css';

function TrackingInformation() {
    const [trackingData, setTrackingData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [trackingInfo, setTrackingInfo] = useState({
        trackingNumber: '',
        productName: '',
        email: '',
        estimatedTimeOfArrival: '',
        orderStatus: '',
        timestamp: ''
    });

    const filteredTrackingData = trackingData.filter((tracking) =>
        tracking.trackingNumber.toLowerCase().includes(searchQuery.toLowerCase())
    );


    useEffect(() => {
        fetchTrackingData();
    }, []);

    const fetchTrackingData = async () => {
        try {
            const response = await axios.get('https://p2pbackendserver-six.vercel.app/tracking-info');
            setTrackingData(response.data);
        } catch (error) {

            console.error('Error fetching tracking information:', error);
        }
    };

    const handleEdit = (index) => {
        setEditIndex(index);
        setTrackingInfo(trackingData[index]);
    };


    return (
        <div>
                    <div style={{width:"100%", display: "flex"}}>
        <h2 className='t-info-heading'>Tracking Information</h2>
        </div>
            <div className="tracking-search-section">
                <input
                    type="text"
                    placeholder="Search by Tracking Number"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            <div className="tracking-info-section">
                {filteredTrackingData.map((tracking, index) => (
                    <div key={index} className="tracking-card">
                        <div className="edit-box">
                            <p>Tracking Number: {tracking.trackingNumber}</p>
                        </div>
                        <div className="edit-box">
                            <p>Product Name: {tracking.productName}</p>
                        </div>
                        <div className="edit-box">
                            <p>Email: {tracking.email}</p>
                        </div>
                        <div className="edit-box">
                            <p>Estimated Time of Arrival: {tracking.estimatedTimeOfArrival}</p>
                        </div>
                        <div className="edit-box">
                            <p>Order Status: {tracking.orderStatus}</p>
                        </div>
                        <div className="edit-box">
                            <p>{new Date(tracking.timestamp).toLocaleString()}</p>
                        </div>
                        <button className='tracking-edit-btn' onClick={() => handleEdit(index)}>Edit<img width="10" height="10" src="https://img.icons8.com/ios/50/edit--v1.png" alt="edit--v1" /></button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TrackingInformation
