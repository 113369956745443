import React, { useState, useEffect } from 'react';
import { useLocation, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import Homepage from './pages/Homepage';
import About from './pages/About';
import Contact from './pages/Contact';
import Schedule from './pages/Schedule';
import ShipWithUs from './pages/ShipWithUs';
import DisplayTrackingInfo from './pages/DisplayTrackingInfo';
import MoreinfooOfProcess from './pages/MoreinfooOfProcess';
import ForBetterExperience from './pages/ForBetterExperience';
import Articles from './pages/Articles';
import ArticleDetails from './pages/ArticleDetails';
import CreateArticle from './pages/CreateArticle';
import EditArticle from './pages/EditArticle';
import QuotePage from './pages/QuotePage';
import Legal from './pages/Legal';
import FAQ from './pages/FAQ';
import { Toaster } from 'react-hot-toast';
import Footer from './components/Footer';
import Trackingpage from './pages/Trackinginput';
import ScrollToTop from './components/ScrollToTop';
import AddAddress from './components/Addaddress';
import Dash from './components/Dash';
import ConnectionStatus from './components/ConnectionStatus';
import Error from './components/Error';
import SupportAdmin from './pages/SupportAdmin';
import ForgotPassword from './pages/ForgetPassword';
import ResetPassword from './pages/ResetPassword';
import NotSignedInAsAdmin from './pages/NotSignedInAsAdmin';
import AdminChat from './SupportChat/SelfChat/AdminChat';
import ReactGA from 'react-ga';
import './fonts/fonts.css';
import UserDashboard from './User/UserDashboard';
import Notsignedinasuser from './pages/Notsignedinasuser';


const App = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page view on route change
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const checkLoggedIn = () => {
    const token = localStorage.getItem('token');
    return token !== null;
  };

  const isLoggedIn = () => {
    return checkLoggedIn();
  };

  if (loading && !isLoggedIn()) {
    return (
      <div className='intro-box'>
        <div className="three-body">
          <div className="three-body__dot"></div>
          <div className="three-body__dot"></div>
          <div className="three-body__dot"></div>
        </div>
      </div>
    );
  }


  const noFooterRoutes = ['/login', '/register', '/forgot-password', '/reset-password/:token', '/dashboard', '/profile'];

  return (
    <div>
      <Toaster position='top-right' toastOptions={{ duration: 2000 }} />
      <ConnectionStatus />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/ship-with-us" element={<ShipWithUs />} />
        <Route path="/tracking/:uuid" element={<DisplayTrackingInfo />} />
        <Route path="/dashboard" element={isLoggedIn() && localStorage.getItem('username') === 'p2p_superadmin' ? <Dash /> : <NotSignedInAsAdmin />} />
        <Route path="/profile" element={isLoggedIn() && localStorage.getItem('username') !== 'p2p_superadmin' ? <UserDashboard /> : <Login />} />
        <Route path="/process" element={<MoreinfooOfProcess />} />
        <Route path="/quote" element={<QuotePage />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/better-experience-query" element={<ForBetterExperience />} />
        <Route path="/addaddress" element={<AddAddress />} />
        <Route path="/tracking-page" element={<Trackingpage />} />
        <Route path="/support-admin" element={<SupportAdmin />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        
        {/* Blog Routes */}
        <Route path="/blog" element={<Articles />} />
        <Route path="/article/:id" element={<ArticleDetails />} />
        <Route path="/create-article" element={<CreateArticle />} />
        <Route path="/edit/:id" element={<EditArticle />} />
        
        <Route path="admin-chat" element={<AdminChat />} />
        <Route path="*" element={<Error />} />
      </Routes>

      {!noFooterRoutes.includes(location.pathname) && <Footer />}
    </div>
  );
};

export default App;
