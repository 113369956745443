import React from 'react';
import '../styles/Slider.css'; // This will be your updated CSS file
import amazon from '../assets/images/Shops/amazon-img.png';
import ebay from '../assets/images/Shops/ebay-img.png';
import shein from '../assets/images/Shops/shein.png';
import asos from '../assets/images/Shops/asos.webp';
import next from '../assets/images/Shops/next.jpg';
import nike from '../assets/images/Shops/nike.png';
import adidas from '../assets/images/Shops/Adidas.png';
import sportsdirect from '../assets/images/Shops/sportsdirect.png';
import marksandspencer from '../assets/images/Shops/m&s.jpeg';

const Slider = () => {
  const images = [amazon, ebay, shein, asos, next, nike, adidas, sportsdirect, marksandspencer];
  const imagelinks = [
    'https://www.amazon.com/', 'https://www.ebay.co.uk/', 'https://m.shein.com/', 'https://www.asos.com/',
    'https://www.next.co.uk/', 'https://www.nike.com/', 'https://www.adidas.com/us', 'https://www.sportsdirect.com/',
    'https://www.marksandspencer.com/'
  ];

  return (
    <div className='tile-main'>
      <h2 className="tile-heading">Shop with us</h2>
      <div className="tile-container">
      {images.map((image, index) => (
        <div key={index} className="tile-box" onClick={() => window.location.href = imagelinks[index]}>
          <img loading="lazy" src={image} alt="store-img" className="tile-image" />
        </div>
      ))}
    </div>
    </div>

  );
};

export default Slider;
