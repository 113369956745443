import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import '../styles/ReadMore.css';
import leftarrow from '../assets/images/rightarrow.png'; // Replace with your actual icon path
// import exampleIcon from '../assets/example-icon.svg'; // Replace with your actual icon path

const ReadMoreDirection = () => {

 const navigate = useNavigate();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
  };



    const handlenav = () => {
    navigate('/process');
    }

  return (
    <div className='readmoreOuter'>
    <motion.div
      ref={ref}
      className="readmore-container"
      variants={containerVariants}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
    >
      <motion.div className="readmore-column" variants={itemVariants}>
        <h2>What we do</h2>
        <motion.div variants={itemVariants}>
        <button onClick={handlenav} className="readmore-button">
          Read More
          <div className="button-icon-container">
          <img width="20" height="20" src={leftarrow} alt="long-arrow-right"/>
          </div>
        </button>
      </motion.div>
      </motion.div>
      <motion.div className="readmore-column" id='changetext' variants={itemVariants}>
        <p>
        We provide our customers with a UK shipping address, allowing them to shop from their favorite online stores and have their purchases delivered to us. We then ship their packages safely and efficiently to Ghana. Whether you’re an individual or a business, our service ensures your items are delivered reliably, on time, and with care.
        </p>
      </motion.div>

    </motion.div>
    </div>
  );
};

export default ReadMoreDirection;
