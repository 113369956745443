import React from 'react'
import '../styles/CreateAccount.css';
import { useNavigate } from 'react-router-dom';

function CreateAccount() {


    const navigate = useNavigate();

    const handlenavigate = () => {
        navigate('/register')
    }

  return (
    <div className='creat-account'>
      <button className='create-account-btn' onClick={handlenavigate}>Create Account</button>
    </div>
  )
}

export default CreateAccount
