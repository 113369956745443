import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/ChangePassword.css';
import { toast } from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';

const ChangePassword = () => {
  const [username, setUsername] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  const validateForm = () => {
    if (!oldPassword || !newPassword || !confirmPassword) {
      toast.error('All fields are required');
      return false;
    }
    if (newPassword !== confirmPassword) {
      toast.error('New passwords do not match');
      return false;
    }
    if (newPassword.length < 8) {
      toast.error('New password must be at least 8 characters long');
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setShowConfirmation(true);
    }
  };

  const handleLogout = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('_id');
    navigate('/login');
  };

  const confirmPasswordChange = async () => {
    setShowConfirmation(false);
    const token = localStorage.getItem('token');

    if (!token) {
      toast.error('You are not authenticated. Please log in again.');
      return;
    }
    try {
      const response = await axios.post('https://p2pbackendserver-six.vercel.app/change-password', 
        { username, oldPassword, newPassword },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      toast.success(response.data.message);
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
      handleLogout();
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error || 'An error occurred while changing the password');
      } else {
        toast.error('Network error. Please try again later.');
      }
    }
  };

  return (
    <div className="change-password-container">
      <h2>Change Password</h2>
      <form onSubmit={handleSubmit} className="change-password-form">
        <div className="form-group">
          <input 
            style={{ cursor: 'not-allowed', pointerEvents: 'none', opacity: 0.5, backgroundColor: 'lightgray' }}
            type="text"
            id="username"
            value={username}
            readOnly
            className="form-control"
            placeholder="Username"
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            id="oldPassword"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            className="form-control"
            placeholder="Old Password"
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="form-control"
            placeholder="New Password"
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="form-control"
            placeholder="Confirm Password"
          />
        </div>
        <button type="submit" className="submit-button">Change Password</button>
      </form>

      {showConfirmation && (
        <div className="confirmation-modal">
          <div className="confirmation-content">
            <h3>Are you sure you want to change your password?</h3>
            <div className="confirmation-buttons">
              <button onClick={confirmPasswordChange} className="confirm-button">Yes, Change Password</button>
              <button onClick={() => setShowConfirmation(false)} className="cancel-button">Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
